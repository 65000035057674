import * as React from 'react';
import Header from '../components/LandingPageHeader';
import Hero from '../components/CarrierHero';
import Footer from '../components/LandingPageFooter';
import SEO from '../components/SEO';
import CarrierContent from '../components/CarrierContent';

export default function CarrierPage(props) {
	return (

		<SEO {...props}>
			<main className="flex flex-col">
				<Header />
				<Hero />
				<CarrierContent />
				<Footer />
			</main>
		</SEO>
	);
}
