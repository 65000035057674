import React from 'react';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';
import CurvedHorizontalDivider from './subcomponents/CurvedHorizontalDivider';
import CurvedHorizontalDividerMobile from './subcomponents/CurvedHorizontalDividerMobile';
// import { trackEvent } from '../models/analytics';
import heroGraphic from '../assets/HeroGraphic.svg';
import techstarsLogo from '../assets/techstars.svg';
import diLogo from '../assets/di.svg';
import inLogo from '../assets/investopedia.svg';

export default function CarrierHero() {
	return (
		<>
			<div className="relative bg-white">
				<main className="lg:relative">
					<div className="flex mx-auto max-w-7xl w-full pt-8 pb-0 xl:pb-5  lg:pt-20 lg:pb-8 2xk:py-24 text-left">
						<div className="relative px-4 lg:w-3/5 sm:px-8 xl:pr-16">
							<img
								src={heroGraphic}
								alt="Swirl Line"
								className="-left-20 -top-12 absolute overflow-hidden"
							/>
							<div className="relative">
								{/* for some reason, that relative class is what keeps our text in front of the swirly, otherwise the swirly goes in front. z-index didn't fix it. */}
								{!isMobile ? (
									<h1>
										Go Digital with Buddy's Insurance Gateway.
									</h1>
								) : (
									<h1>
										Go Digital with Buddy's Insurance Gateway.
										{/* <br />
										to your application */}
									</h1>
								)}
								{' '}
								<h3 className="">
									Buddy is the Insurance Gateway for people who build software.
									{' '}
									Our tools are designed to make insurance easy to transact in digital contexts.
								</h3>
							</div>
						</div>
						<div className="hidden lg:flex justify-end items-center w-full lg:w-1/3 lg:h-4/5 xl:h-full 2xl:h-full 2xl:pr-20">
							<StaticImage
								alt="buddy on desktop and mobile"
								placeholder="blur"
								src="../assets/hero_device_mockup.png"
								loading="eager"
							/>
						</div>
					</div>
				</main>
			</div>
			<div className="bg-white">
				<div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
					<h3 className="container text-center relative rbo-item">
						We've been honored by some great organizations and publishers along the way.
					</h3>
					<div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
						<div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
							<img
								src={techstarsLogo}
								alt="techstars"
								className="max-h-12"
							/>
						</div>
						<div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
							<img
								src={diLogo}
								alt="Digital Insurance"
								className="max-h-12"
							/>
						</div>
						<div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
							<img
								src={inLogo}
								alt="Investopedia"
								className="max-h-12"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="sm:px-8 px-8 flex flex-col items-center mt-5 xl:my-0" style={{ overflow: 'hidden' }}>
				{!isMobile ? (
					<CurvedHorizontalDivider />
				) : (
					<CurvedHorizontalDividerMobile />
				)}
			</div>
		</>
	);
}
