import * as React from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { StaticImage } from 'gatsby-plugin-image';
import CurvedHorizontalDivider from './subcomponents/CurvedHorizontalDivider';
import CurvedHorizontalDividerMobile from './subcomponents/CurvedHorizontalDividerMobile';
import CarrierModal from './CarrierModal';

const features = [
	{ name: 'Upload your forms and rates', icon: '1.', content: 'Submit an approved form and rating manual or just give us a SERFF filing number.' },
	{ name: 'Answer a few quick questions', icon: '2.', content: 'Tell us a bit about the product and the context in which you’d like to see it work for your company.' },
	{ name: 'Buddy will ingest your product', icon: '3.', content: 'You’ll see how Buddy’s Insurance Gateway makes transacting any insurance product inside of software simple.' },
];

export default function CarrierContent() {
	const [open, setOpen] = useState(false);
	const onStartClick = () => {
		setOpen(!open);
	};
	return (
		<>
			<div className="relative bg-white py-16 sm:py-24 lg:py-13">
				<div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
					{/* <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Deploy faster</h2> */}
					{/* <h2 className="text-3xl lg:text-4xl lg:mx-60 font-bold text-gray-900">
						If need to reduce your engineering insurance product or program could be a bit easier to handle,
						{' '}
						you've come to the right place.
					</h2>
					<p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
						Buddy's Insurance Gateway can ingest
						{' '}
						new products quickly, turning 📜 into 💻!
					</p> */}
					<h2 className="text-3xl lg:text-4xl font-bold text-gray-900">
						<span style={{ maxWidth: '650px !important' }}>
							Don't want to wait months to digitize your insurance?
						</span>
					</h2>
					<p className="mt-5 max-w-prose mx-auto text-xl text-gray-500" style={{ maxWidth: '650px !important' }}>
						Buddy's Insurance Gateway gets insurance companies capitalizing on
						{' '}
						digital deals by turning 📜 into 💻 in days.
					</p>
					<div className="mt-12">
						<div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
							{features.map((feature) => (
								<div key={feature.name} className="pt-6">
									<div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
										<div className="-mt-6">
											<div>
												<span className="inline-flex items-center justify-center p-3 rounded-md shadow-lg" style={{ backgroundColor: '#dc5648' }}>
													<h3 className="text-white font-bold">
														{feature.icon}
													</h3>
												</span>
											</div>
											<h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
											<p className="mt-5 text-base text-gray-500 text-left">
												{feature.content}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="text-center mt-10">
						<button type="button" onClick={onStartClick} className="inline-flex text-white items-center h-10 px-5 rounded-lg focus:shadow-outline" style={{ backgroundColor: '#dc5648' }}>
							<span className="text-xl">Start Here</span>
							<svg className="w-4 h-4 ml-3 fill-current" viewBox="0 0 20 20"><path d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" fillRule="evenodd" /></svg>
						</button>
					</div>
					<div className="sm:px-8 px-8 flex flex-col items-center mt-5 xl:my-0" style={{ overflow: 'hidden' }}>
						{!isMobile ? (
							<CurvedHorizontalDivider />
						) : (
							<CurvedHorizontalDividerMobile />
						)}
					</div>
					<div className="text-center lg:mt-10 flex justify-center">
						<blockquote className="relative max-w-3xl bg-white rounded-lg shadow-lg">
							<div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
								{/* <div className="flex justify-end">
									<img
										src="https://tailwindui.com/img/logos/workcation-logo-indigo-600-mark-gray-800-and-indigo-600-text.svg"
										alt="Workcation"
										className="h-8"
									/>
								</div> */}
								<div className="relative text-lg text-gray-700 font-medium mt-8">
									<svg
										className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
										fill="currentColor"
										viewBox="0 0 32 32"
										aria-hidden="true"
									>
										<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
									</svg>
									<p className="relative">
										Working with Buddy to embed insurance in The Forge's checkout process was incredibly simple,
										{' '}
										and our guests can now choose from contextually relevant ways to cover their experiences on-site.
									</p>
								</div>
							</div>
							<cite className="relative flex items-center sm:items-start bg-gray-50 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
								<div className="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
									<StaticImage
										src="../assets/forge-ceo.png"
										className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300"
										alt="CEO"
									/>
								</div>
								<span className="relative ml-4 text-black font-semibold leading-6 sm:ml-24 sm:pl-1">
									<p className="text-black font-semibold sm:inline">Byron Bell - </p>
									<p className="sm:inline">General Manager at The Forge Lemont Quarries</p>
								</span>
							</cite>
						</blockquote>
					</div>
				</div>
			</div>
			<div className="bg-gray-50">
				<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
					<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
						<span className="block">Any Questions?</span>
						<span className="block" style={{ color: '#dc5648' }}>Contact Buddy</span>
					</h2>
					<div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
						<div className="inline-flex rounded-md shadow">
							<a
								href="/contact"
								className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white"
								style={{ backgroundColor: '#dc5648' }}
							>
								Talk to Buddy
							</a>
						</div>
						<div className="ml-3 inline-flex rounded-md shadow">
							<a
								href="/"
								className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-white"
								style={{ color: '#dc5648' }}
							>
								Visit Buddy
							</a>
						</div>
					</div>
				</div>
			</div>
			<CarrierModal open={open} setOpen={setOpen} />
		</>
	);
}
