import * as React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { isMobile } from 'react-device-detect';

export default function CarrierModal({ open, setOpen }) {
	const modalIframeHeight = isMobile ? 850 : 690;
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div style={{ backgroundColor: '#f4f8fa', overflow: 'hidden' }} className="inline-block align-bottom rounded-lg px-4 pb-4 text-left shadow-xl transform transition-all sm:align-middle w-full sm:w-full md:w-2/3">

							<div className="text-center w-100 h-100">

								<iframe
									style={{
										marginTop: 0, minHeight: '690px', height: modalIframeHeight, width: '100%', overflow: 'hidden',
									}}
									title="carrier"
									src="https://share.hsforms.com/1Cit6jYn_R326lijt98sKEw55249"
								/>

							</div>

							{/* <div className="mt-5 sm:mt-6">
								<button
									type="button"
									className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm"
									onClick={() => setOpen(false)}
									style={{ backgroundColor: '#dc5648' }}
								>
									Close
								</button>
							</div> */}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
