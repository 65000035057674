import React from 'react';

const InstagramFooter = ({
	width = '32',
	height = '32',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M22.4167 5H9.5833C7.05201 5 5 7.05201 5 9.5833V22.4167C5 24.948 7.05201 27 9.5833 27H22.4167C24.948 27 27 24.948 27 22.4167V9.5833C27 7.05201 24.948 5 22.4167 5Z" fill="white" />
		<path d="M5 18H27V22.75C27 23.8772 26.5522 24.9582 25.7552 25.7552C24.9582 26.5522 23.8772 27 22.75 27H9.25C8.12283 27 7.04183 26.5522 6.2448 25.7552C5.44777 24.9582 5 23.8772 5 22.75V18Z" fill="#46460E" fillOpacity="0.2" />
		<path d="M22.5 5.5C23.5605 5.50119 24.5772 5.923 25.3271 6.67289C26.077 7.42278 26.4988 8.4395 26.5 9.5V22.5C26.4988 23.5605 26.077 24.5772 25.3271 25.3271C24.5772 26.077 23.5605 26.4988 22.5 26.5H9.5C8.4395 26.4988 7.42278 26.077 6.67289 25.3271C5.923 24.5772 5.50119 23.5605 5.5 22.5V9.5C5.50119 8.4395 5.923 7.42278 6.67289 6.67289C7.42278 5.923 8.4395 5.50119 9.5 5.5H22.5ZM22.5 4H9.5C8.04131 4 6.64236 4.57946 5.61091 5.61091C4.57946 6.64236 4 8.04131 4 9.5L4 22.5C4 23.9587 4.57946 25.3576 5.61091 26.3891C6.64236 27.4205 8.04131 28 9.5 28H22.5C23.9587 28 25.3576 27.4205 26.3891 26.3891C27.4205 25.3576 28 23.9587 28 22.5V9.5C28 8.04131 27.4205 6.64236 26.3891 5.61091C25.3576 4.57946 23.9587 4 22.5 4V4Z" fill="#0A242D" />
		<path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" fill="white" />
		<path d="M16 12.5C16.6922 12.5 17.3689 12.7053 17.9445 13.0899C18.5201 13.4744 18.9687 14.0211 19.2336 14.6606C19.4985 15.3001 19.5678 16.0039 19.4328 16.6828C19.2977 17.3618 18.9644 17.9854 18.4749 18.4749C17.9854 18.9644 17.3618 19.2977 16.6828 19.4327C16.0039 19.5678 15.3002 19.4985 14.6606 19.2336C14.0211 18.9687 13.4744 18.5201 13.0899 17.9445C12.7053 17.3689 12.5 16.6922 12.5 16C12.5011 15.0721 12.8701 14.1824 13.5263 13.5263C14.1824 12.8701 15.0721 12.5011 16 12.5V12.5ZM16 11C15.0111 11 14.0444 11.2932 13.2222 11.8427C12.3999 12.3921 11.759 13.173 11.3806 14.0866C11.0022 15.0002 10.9031 16.0055 11.0961 16.9755C11.289 17.9454 11.7652 18.8363 12.4645 19.5355C13.1637 20.2348 14.0546 20.711 15.0246 20.9039C15.9945 21.0969 16.9998 20.9978 17.9134 20.6194C18.827 20.241 19.6079 19.6001 20.1574 18.7779C20.7068 17.9556 21 16.9889 21 16C21 14.6739 20.4732 13.4021 19.5355 12.4645C18.5979 11.5268 17.3261 11 16 11V11Z" fill="#0A242D" />
		<path d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z" fill="#0A242D" />
	</svg>
);

export default InstagramFooter;
