import React from 'react';

const FacebookFooter = ({
	width = '32',
	height = '32',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.636 28H11.8671V19.3849H8.1748V12.6149H11.8671V11.0772C11.8669 10.1477 12.0498 9.22728 12.4054 8.36851C12.7611 7.50974 13.2824 6.72945 13.9397 6.07222C14.597 5.41499 15.3773 4.89371 16.2361 4.53815C17.0949 4.18259 18.0153 3.99972 18.9448 4H23.5602V10.7689H18.9448C18.9043 10.7689 18.8642 10.7768 18.8268 10.7923C18.7894 10.8078 18.7554 10.8305 18.7267 10.8591C18.6981 10.8877 18.6754 10.9218 18.6599 10.9592C18.6444 10.9966 18.6365 11.0367 18.6365 11.0772V12.6151H23.8198L22.126 19.3851H18.636V28Z" fill="white" />
		<path d="M18.6361 17.8848H11.8672V27.9999H18.6361V17.8848Z" fill="#D3D3C7" />
		<path d="M18.636 12.6151V11.0773C18.6359 11.0368 18.6438 10.9967 18.6593 10.9592C18.6748 10.9218 18.6975 10.8877 18.7261 10.8591C18.7548 10.8304 18.7888 10.8077 18.8262 10.7922C18.8637 10.7768 18.9038 10.7688 18.9443 10.7689H23.5598V4H18.9448C18.0153 3.9997 17.0948 4.18255 16.236 4.53811C15.3772 4.89366 14.5969 5.41496 13.9396 6.0722C13.2823 6.72945 12.761 7.50976 12.4054 8.36855C12.0498 9.22735 11.8669 10.1478 11.8672 11.0773V12.6151H8.1748V19.3851H11.8672V28H13.3672V17.8849H9.6748V14.1149H13.3672V11.0773C13.367 10.3448 13.5111 9.61942 13.7913 8.94263C14.0716 8.26584 14.4824 7.6509 15.0004 7.13295C15.5183 6.615 16.1333 6.20419 16.8101 5.92401C17.4869 5.64382 18.2123 5.49974 18.9448 5.5H22.0598V9.2689H18.9448C18.7073 9.26883 18.4721 9.31557 18.2527 9.40642C18.0333 9.49728 17.8339 9.63049 17.666 9.79843C17.498 9.96637 17.3648 10.1658 17.274 10.3852C17.1831 10.6046 17.1364 10.8398 17.1365 11.0773V14.1151H21.8982L20.9551 17.8851H17.136V28H18.636V19.3849H22.126L23.8198 12.6149L18.636 12.6151Z" fill="#0A242D" />
	</svg>

);

export default FacebookFooter;
