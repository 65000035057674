import React from 'react';

const TwitterFooter = ({
	width = '32',
	height = '32',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M29.8857 5C28.6683 7.614 26.9221 10.678 26.9221 10.678C26.9317 12.4158 26.7025 14.1468 26.2408 15.8222C25.5629 18.3485 24.2398 20.6551 22.4012 22.5156C20.5626 24.376 18.2718 25.7264 15.7537 26.4341C11.4758 27.6759 6.5344 26.8261 2 24.3547C5.0993 24.4754 7.6582 23.3225 10.2242 21.6134C-0.9906 16.7241 4.7416 6.5358 4.7416 6.5358C5.96947 8.28927 7.61519 9.7089 9.52983 10.6662C11.4445 11.6236 13.5676 12.0884 15.7071 12.0186V10.6479C15.6986 9.52665 16.04 8.43065 16.6837 7.51253C17.3273 6.5944 18.2413 5.89986 19.2983 5.5256C21.4873 4.7056 23.7654 4.8018 25.3019 6.5356C26.8718 6.15863 28.4056 5.6448 29.8857 5V5Z" fill="#46460E" fillOpacity="0.2" />
		<path d="M26.9221 10.678C26.9221 10.678 28.6685 7.614 29.8857 5C28.4055 5.64486 26.8717 6.15876 25.3018 6.5358C23.7654 4.8018 21.4873 4.7058 19.2983 5.5258C18.2413 5.90004 17.3273 6.59455 16.6836 7.51264C16.0399 8.43072 15.6985 9.52667 15.707 10.6479V12.0186C13.5675 12.0884 11.4444 11.6236 9.52978 10.6662C7.61518 9.70889 5.96949 8.28926 4.74166 6.5358C3.88184 8.20749 3.38672 10.0427 3.28906 11.92C6.67286 15.42 13.1377 19.4583 24.8252 19.2632C25.4296 18.1754 25.9047 17.0206 26.2407 15.8223C26.7024 14.1468 26.9317 12.4159 26.9221 10.678Z" fill="white" />
		<path d="M21.8391 6.5272C22.2793 6.50922 22.7179 6.59 23.1229 6.76363C23.5278 6.93725 23.8887 7.19932 24.1791 7.5306L24.7653 8.1923L25.6284 8C26.0408 7.908 26.4521 7.801 26.8525 7.6859C26.1707 8.9654 25.6284 9.9187 25.6189 9.9359L25.4199 10.285L25.4221 10.6868C25.431 12.2847 25.2205 13.8762 24.7966 15.4168C24.1862 17.6969 22.993 19.7789 21.3341 21.458C19.6752 23.1372 17.6079 24.3557 15.3354 24.9937C14.137 25.3354 12.8962 25.5059 11.65 25.5C10.1798 25.4915 8.71717 25.2889 7.3 24.8976C8.61972 24.3521 9.87863 23.6698 11.0561 22.8618L13.3449 21.3374L10.8239 20.2384C7.8427 18.9384 5.9426 17.1326 5.176 14.8701C4.63936 13.082 4.65411 11.1736 5.2183 9.394C7.91782 12.0555 11.5612 13.54 15.3521 13.5231C15.4829 13.5231 15.6135 13.5215 15.7454 13.5181L17.207 13.4807V12.0186V10.6273C17.2037 9.81518 17.4542 9.02232 17.9234 8.35946C18.3926 7.69659 19.0572 7.19684 19.8242 6.93C20.4664 6.67662 21.1488 6.54011 21.8391 6.5269V6.5272ZM29.8857 5C28.4056 5.64487 26.8718 6.15877 25.302 6.5358C24.8703 6.04633 24.3364 5.65755 23.7381 5.39689C23.1398 5.13623 22.4915 5.01003 21.8391 5.0272C20.9693 5.04079 20.1088 5.20954 19.2983 5.5255C18.2413 5.89979 17.3274 6.59434 16.6837 7.51246C16.04 8.43057 15.6986 9.52655 15.707 10.6478V12.0186C15.5881 12.0216 15.4702 12.0231 15.3521 12.0231C13.2716 12.0344 11.2194 11.5411 9.37144 10.5854C7.52343 9.62968 5.93473 8.24012 4.7415 6.5358C4.7415 6.5358 -0.990701 16.7241 10.2244 21.6134C7.97256 23.304 5.25666 24.2638 2.4426 24.3634C2.2964 24.3634 2.14887 24.3605 2 24.3547C4.94716 26.024 8.2636 26.9331 11.65 27C13.0376 27.006 14.4191 26.8155 15.7533 26.4341C18.2714 25.7264 20.5621 24.376 22.4007 22.5156C24.2393 20.6552 25.5624 18.3486 26.2403 15.8223C26.7019 14.1468 26.9312 12.4159 26.9217 10.678C26.9217 10.678 28.6682 7.614 29.8857 5Z" fill="#0A242D" />
	</svg>

);

export default TwitterFooter;
